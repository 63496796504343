import React, { createContext, useState } from 'react';

// Context
export const MasterStore = createContext({});

// Provider
const StoreProvider = ({ children }) => {
    const [FieldData, setFieldData] = useState(null)
    const [Actor, setActor] = useState(null)
    const [VisitType, setVisitType] = useState(null)
    const [SpecialtyId, setSpecialtyId] = useState(null)
    const [PatientId, setPatientId] = useState(null)
    const [PatientName, setPatientName] = useState('')
    const [LocationId, setLocationId] = useState(null)
    const [CurrentLong, setCurrentLong] = useState(null)
    const [CurrentLat, setCurrentLat] = useState(null)
    const [CategoryId, setCategoryId] = useState(null)
    const [CategoryServiceId, setCategoryServiceId] = useState([])
    const [ExecutePlan, setExecutePlan] = useState([])
    const [CloneList, setCloneList] = useState([]);
    const [ActorData, setActorData] = useState([])
    const [Show, setShow] = useState(false)
    const [Visible, setVisible] = useState(false)
    const [AppointMentDayData, setAppointMentDayData] = useState(null)
    const [ForWhoListData, setForWhoListData] = useState({})
    const [ActorPrice, setActorPrice] = useState('')
    const [NurseGender, setNurseGender] = useState('')
    const [ServiceType, setServiceType] = useState('')
    const [ActorRole, setActorRole] = useState(null)
    const [ActorId, setActorId] = useState(null)
    const [SubmitAction, setSubmitAction] = useState(false)
    const [ListSave, setListSave] = useState(false)
    const [IsSubmit, setIsSubmit] = useState(true)
    const [CurrentDate, setCurrentDate] = useState(null)
    const [LatValue, setLatValue] = useState(null)
    const [LongValue, setLongValue] = useState(null)

    return (
        <MasterStore.Provider value={{
            FieldData, setFieldData,
            Actor, setActor,
            VisitType, setVisitType,
            SpecialtyId, setSpecialtyId,
            PatientId, setPatientId,
            PatientName, setPatientName,
            LocationId, setLocationId,
            CurrentLong, setCurrentLong,
            CurrentLat, setCurrentLat,
            CategoryId, setCategoryId,
            CategoryServiceId, setCategoryServiceId,
            ExecutePlan, setExecutePlan,
            ActorData, setActorData,
            Show, setShow,
            AppointMentDayData, setAppointMentDayData,
            ForWhoListData, setForWhoListData,
            ActorPrice, setActorPrice,
            NurseGender, setNurseGender,
            ActorRole, setActorRole,
            ActorId, setActorId,
            SubmitAction, setSubmitAction,
            IsSubmit, setIsSubmit,
            CurrentDate, setCurrentDate,
            ServiceType, setServiceType,
            LatValue, setLatValue,
            CloneList, setCloneList,
            ListSave, setListSave,
            Visible, setVisible,
            LongValue, setLongValue
        }}>
            {children}
        </MasterStore.Provider>
    )
}

export default StoreProvider;