import React from "react";
import ReactDOM from "react-dom";
import App from "./app";
import * as serviceWorker from "./serviceWorker";

//style
import "./index.css";

// Vendors Styles
import "bootstrap/dist/css/bootstrap.min.css";

// Scripts
import "bootstrap/dist/js/bootstrap.bundle";

import StoreProvider from "./Store/StoreProvider";

ReactDOM.render(
  <StoreProvider>
    <App />
  </StoreProvider>,
  document.getElementById("root")
);

serviceWorker.unregister();
